<template>
  <b-modal
    id="validate-modal"
    :title="modalTitle"
    centered
    @hide="() => handleCancel('forceCancel')"
  >
    <template #default>
      You are about to validate a course registration to be submitted for
      processing at University of the Pacific, Benerd College. Please review the
      information below prior to confirming validation.
    <ul style="margin-top: 10px">
      <li>UC Order ID: {{ order.order }}</li>
      <li>First Name: {{ order.first_name }}</li>
      <li>Last Name: {{ order.last_name }}</li>
      <li>Course Number: {{ order.course ? order.course.course_number : ""}}</li>
      <li>Course Title: {{ order.course ? order.course.course_title : "" }}</li>
      <li>Credit(s): {{ order.course_credits }}</li>
      <li>Course Credit Fee: ${{ order.course_credit_fee }}</li>
      <li>Course Fixed Credit Fee: ${{ order.course_fixed_credit_fee }}</li>
      <li>Total Course Fee: ${{ totalCreditFee }}</li>
    </ul>
    </template>
    <template #modal-footer="{ cancel /*hide, ok */ }">
      <b-button
        class="btn-red btn"
        variant="danger"
        @click="() => handleCancel(cancel)"
        :disabled="stage === 'processing'"
      >
        CANCEL
      </b-button>
      <b-button
        variant="success"
        class="btn-green btn btn--with-loader"
        @click="
          () => {
            $emit('confirmValidateOrder');
          }
        "
        :disabled="stage === 'processing'"
      >
        {{ next ? "CONFIRM & NEXT" : "CONFIRM" }}
        <b-spinner
          style="width: 20px; height: 20px"
          v-if="stage === 'processing'"
          label="Loading..."
        ></b-spinner>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    stage: {
      type: String,
      default: "",
    },
    order: {
      type: Object,
      default: () => ({}),
    },
    modalMessage: {
      type: String,
      default: "",
    },
    modalTitle: {
      type: String,
      default: "Click CONFIRM to Validate Course",
    },
    next: {
      type: Object,
      default: null,
    },
  },
  computed: {
    totalCreditFee() {
      return (
        (this.order.course_credits ?? 0) * (this.order.course_credit_fee ?? 0) +
        (this.order.course_fixed_credit_fee ?? 0)
      );
    },
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        document.getElementById("modal").style.display = "block";
      } else {
        document.getElementById("modal").style.display = "none";
      }
    },
    stage(newVal) {
      if (newVal === "success" || newVal === "error") {
        this.$emit("closeDialog");
        this.$root.$emit("bv::hide::modal", "validate-modal");
      }
    },
  },
  methods: {
    handleCancel(action) {
      this.$emit("next", { action, type: "validateBulk" });
    },
  },
};
</script>


<style scoped>
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
</style>